import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISpinsStore} from "data/stores/wheel/spins.store";
import type {IEventsStore} from "data/stores/events/events.store";
import {DateTime} from "luxon";
import {MEDIA_URL} from "data/constants";

interface IParams {
	eventId: number;
}
export interface IRaceHeaderController extends ViewController<IParams> {
	get currentEventDate(): string;
	get currentEventName(): string;

	get currentEventFlag(): string | null;
	i18n: ILocalizationStore;

	onImageError: () => void;
}

@injectable()
export class RaceHeaderController implements IRaceHeaderController {
	@observable private _eventId: number = 0;
	@observable private _hasErrorImageLoad: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SpinsStore) public readonly _spinsStore: ISpinsStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get currentEventDate(): string {
		const event = this.currentEvent;
		if (event) {
			const startDay = DateTime.fromISO(event.startDate);
			const endDate = DateTime.fromISO(event.endDate);
			const startDayMonth = DateTime.fromISO(event.startDate).month;
			const endDayMonth = DateTime.fromISO(event.endDate).month;
			if (startDayMonth === endDayMonth) {
				return `${startDay.day}-${endDate.day} ${startDay.toFormat("LLL yyyy")}`;
			}
			return `${startDay.day} ${startDay.toFormat("LLL")}-${endDate.day} ${endDate.toFormat("LLL yyyy")}`;
		}

		return `--`;
	}

	get currentEventName(): string {
		return this.i18n.takeTranslation(this.currentEvent, "name", "--");
	}

	get currentEventFlag(): string | null {
		const flagUrl = this.currentEvent?.flagImage;

		if (!flagUrl || this._hasErrorImageLoad) {
			return null;
		}
		return `${MEDIA_URL}${flagUrl}`;
	}

	get currentEvent() {
		return this._eventsStore.list.find((it) => it.id === this._eventId);
	}

	init(param: IParams) {
		this._eventId = param.eventId;
	}

	onChange(param: IParams) {
		this.init(param);
	}

	onImageError = () => {
		this._hasErrorImageLoad = true;
	};
}
