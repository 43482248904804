import {makeAutoObservable, runInAction, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Countdown} from "data/utils";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IWheelsStore} from "data/stores/wheels/wheels.store";
import type {ISpinsStore} from "data/stores/wheel/spins.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IChecksumStore} from "data/stores/checksum/checksum.store";

interface IParams {
	date: string;
}

export interface IRaceCardTimerController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	get dateValue(): string;
}

@injectable()
export class RaceCardTimerController implements IRaceCardTimerController {
	private _timer: Countdown;

	@observable _timerValues = {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	};
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.EventsStore) public readonly _eventsStore: IEventsStore,
		@inject(Bindings.ChecksumStore) public readonly _checksumStore: IChecksumStore,
		@inject(Bindings.WheelsStore) public readonly _wheelsStore: IWheelsStore,
		@inject(Bindings.SpinsStore) public readonly _spinsStore: ISpinsStore,
		@inject(Bindings.UserStore) public readonly _userStore: IUserStore
	) {
		this._timer = new Countdown(new Date());
		makeAutoObservable(this);
	}

	get dateValue(): string {
		const {days, hours, minutes, seconds} = this._timerValues;
		if (days) {
			return `${days} ${this.i18n.t("timer.days", "Days")}`;
		}

		if (hours) {
			return `${hours}${this.i18n.t("timer.hours", "Hrs")} ${minutes}${this.i18n.t("timer.minutes", "Mins")}`;
		}

		if (minutes) {
			return `${minutes}${this.i18n.t("timer.minutes", "Mins")}`;
		}

		if (seconds) {
			return `${seconds}${this.i18n.t("timer.seconds", "Secs")}`;
		}

		return "--";
	}

	init(param: IParams) {
		this._timer
			.updateDate(new Date(param.date))
			.onTick((days, hours, minutes, seconds) => {
				runInAction(() => {
					this._timerValues = {
						days,
						hours,
						minutes,
						seconds,
					};
				});
			})
			.onComplete(() => {
				void this._checksumStore.fetchChecksums();
				if (!this._userStore.wasLoggedOut) {
					this._userStore.clearUser();
					this._spinsStore.clear();
				}
				// this._spinsStore.clear();
			})
			.run();
	}
	onChange(param: IParams) {
		this.init(param);
	}
}
