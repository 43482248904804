import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface IPostMessageStore {
	get messages(): string[];

	addMessage(message: string): void;
	addClientMessage(message: string): void;
	removeMessage(index: number): void;
	clearMessages(): void;
}

@injectable()
export class PostMessageStore implements IPostMessageStore {
	@observable private _messages: string[] = [];
	@observable private _clientMessages: string[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	get messages(): string[] {
		return this._messages;
	}

	addMessage(message: string) {
		this._messages = [...this._messages, message];
	}

	addClientMessage(message: string) {
		this._clientMessages = [...this._clientMessages, message];
	}

	removeMessage(index: number) {
		this._messages = this._messages.filter((_, messageIndex) => index !== messageIndex);
	}

	clearMessages() {
		this._messages = [];
	}
}
