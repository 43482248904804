import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";
import {MEDIA_URL} from "data/constants";

export interface IContentController extends ViewController {
	get backgroundImageURL(): string | null;
}

@injectable()
export class ContentController implements IContentController {
	constructor(@inject(Bindings.EventsStore) public readonly _eventsStore: IEventsStore) {
		makeAutoObservable(this);
	}

	get backgroundImageURL(): string | null {
		const event = this._eventsStore.currentEvent;
		if (event?.backgroundImage) {
			return `${MEDIA_URL}${event.backgroundImage}`;
		}
		return null;
	}
}
