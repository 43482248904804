import {createTheme} from "@mui/material/styles";

import {buttonsTheme} from "assets/theming/buttons.theme";
import {typographyTheme} from "assets/theming/typography.theme";
import {paletteTheme} from "assets/theming/palette.theme";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";
import {componentsTheme} from "assets/theming/components.theme";

export const theme = createTheme(
	{
		palette: paletteTheme.palette,
		typography: typographyTheme.typography,
		breakpoints: breakpointsTheme,
	},
	buttonsTheme,
	componentsTheme
);
