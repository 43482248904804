import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, Locale} from "data/enums";
import {INavigationRoute} from "data/types/navigation";
import {SelectChangeEvent} from "@mui/material";
import {ENV} from "data/constants";

export interface IHeaderController extends ViewController {
	i18n: ILocalizationStore;

	get locale(): Locale;

	get menuState(): boolean;

	get routes(): INavigationRoute[];

	get isLoggedIn(): boolean;

	logout: () => void;
	login: () => void;

	toggleMenu: () => void;
	handleLangChange: (event: SelectChangeEvent) => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable private _menuState = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public readonly _userStore: IUserStore,
		@inject(Bindings.ModalsStore) public readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get locale() {
		return this.i18n.locale;
	}

	get menuState(): boolean {
		return this._menuState;
	}

	get routes(): INavigationRoute[] {
		const routes: INavigationRoute[] = [
			{
				path: "/",
				label: this.i18n.t("menu.item.home", "Spin To Win"),
			},
			{
				path: "/help/terms-and-conditions",
				label: this.i18n.t("menu.item.terms", "T&Cs"),
			},
			{
				path: "/help/game-rules",
				label: this.i18n.t("menu.item.rules", "Game Rules"),
				external: false,
			},
			{
				path: "/help/contact-us",
				label: this.i18n.t("menu.item.contact_us", "Contact Us"),
				external: false,
			},
		];

		if (this.isLoggedIn && ENV !== "production") {
			routes.push({
				path: "#",
				label: "Logout",
				onClick: this.logout,
			});
		}

		return routes;
	}

	get isLoggedIn(): boolean {
		return this._userStore.isAuthorized;
	}

	logout = () => {
		void this._userStore.logout();
	};

	login = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	toggleMenu = () => {
		this._menuState = !this._menuState;
	};

	handleLangChange = (event: SelectChangeEvent) => {
		void this.i18n.switchLocale({
			locale: event.target.value as Locale,
		});
	};
}
