import * as Sentry from "@sentry/browser";

type TUnknown = Record<string, unknown> | unknown;

export const captureSentryError = (controllerName: string, errors: TUnknown, extra?: TUnknown) => {
	if (Sentry) {
		// Add all the extra context
		Sentry.withScope((scope) => {
			scope.setTag("controllerError", controllerName);

			scope.setContext("extra", {
				extra: JSON.stringify(extra),
			});
			Sentry.captureException(errors, {
				extra: {
					extra: JSON.stringify(extra),
				},
			});
		});
	}
};
