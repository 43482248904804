import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {makeAutoObservable, observable, runInAction, flow, flowResult} from "mobx";
import {Variants, Variant} from "framer-motion";
import {IAnimation} from "data/types/animation";
import {Bindings} from "data/constants/bindings";
import {times} from "lodash";
import {ISlice, SliceType} from "data/stores/wheels/wheels.store";
import {Timings} from "data/utils/timings";

interface IParams {
	size: number;
	radius: number;
	prize: number | null;
	prizeIndex: number | null;
	prizeOption?: ISlice | null;
}

export interface ISpinnerController extends ViewController<IParams> {
	readonly i18n: ILocalizationStore;

	get resultAnimation(): IAnimation;
	get spinnerAnimation(): IAnimation;
	get lightsAnimation(): IAnimation;
	get segmentsAnimations(): IAnimation[];
	get pointerAnimation(): IAnimation;

	get borderGradientAnimation(): IAnimation;
	get hordeWidth(): number;
}

const gradient = `linear-gradient(68deg, #8E9196 10.17%, #1B1B1B 24.81%, #232224 73.52%, #505355 87.59%)`;

@injectable()
export class SpinnerController implements ISpinnerController {
	private _animationFlow: ReturnType<typeof flowResult<Generator>> | null = null;
	private _params: IParams = {
		size: 0,
		radius: 0,
		prize: null,
		prizeIndex: null,
		prizeOption: undefined,
	};

	private _prize: number | null = null;

	@observable _spinnerAnimation = "initial";
	@observable _lightsAnimation = "initial";
	@observable _segmentsAnimation = "initial";
	@observable _resultAnimation = "initial";
	@observable _pointerAnimation = "initial";
	@observable _borderGradientAnimation = "initial";

	constructor(@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	get resultAnimation(): IAnimation {
		return {
			variants: {
				initial: {
					scale: 0.2,
					pointerEvents: "none",
					zIndex: -1,
				},
				start: {
					scale: 1,
					display: "flex",
					pointerEvents: "all",
					zIndex: 2,
				},
			},
			type: this._resultAnimation,
			initial: "initial",
		};
	}

	get spinnerAnimation() {
		const variants: Variants = {
			initial: {
				rotate: 0,
				scale: 0.2,
				transition: {
					duration: 0,
				},
			} as Variant,
			show: {
				rotate: 0,
				scale: 1,
				transition: {
					rotate: {
						duration: 0,
					},
				},
			} as Variant,
			start: {
				scale: 1,
				rotate: this.prizeAnimationOffset,
				transition: {
					ease: "circInOut",
					duration: 4,
				},
			} as Variant,
		};

		return {
			variants: variants,
			initial: "initial",
			type: this._spinnerAnimation,
		};
	}

	get lightsAnimation(): IAnimation {
		const variants: Variants = {
			initial: {
				"--blur-dot-size": "0px",
				rotate: 0,
			} as Variant,
			show: {
				"--blur-dot-size": "4px",
				rotate: 0,
				transition: {
					"--blur-dot-size": {
						repeat: Infinity,
						repeatType: "reverse",
						type: "spring",
						damping: 10,
						mass: 0.75,
						stiffness: 100,
					},
					rotate: {
						duration: 0,
					},
				},
			} as Variant,

			start: {
				"--blur-dot-size": "4px",
				rotate: this.prizeAnimationOffset,
				transition: {
					ease: "circInOut",
					duration: 4,
					"--blur-dot-size": {
						repeat: Infinity,
						repeatType: "reverse",
						type: "spring",
						damping: 10,
						mass: 0.75,
						stiffness: 100,
					},
				},
			} as Variant,
		};

		return {
			variants: variants,
			initial: "initial",
			type: this._spinnerAnimation,
		};
	}

	get segmentsAnimations(): IAnimation[] {
		return times(this._params.size, (n) => n).map((i) => {
			const angleCoef = (i + 1) / this._params.size;
			// const offset = this.offset;
			return {
				variants: {
					initial: {
						width: this.hordeWidth,
						height: this._params.radius,
						rotate: 360 * angleCoef,
						clipPath: "polygon(50% 100%, 100% 0, 100% 0, 0 0, 0 0)",
					},
					blur: {
						filter: "blur(2px)",
					},
					endBlur: {
						filter: "blur(0px)",
					},
				},
				type: this._segmentsAnimation,
				initial: "initial",
			};
		});
	}

	get pointerAnimation(): IAnimation {
		return {
			variants: {
				initial: {
					scale: 0,
					color: "#D9DEE4",
				},
				start: {
					scale: 1,
					display: "block",
					pointerEvents: "all",
					color: "#FF87BC",
				},
				warning: {
					scale: 1,
					display: "block",
					pointerEvents: "all",
					color: "#FF583E",
				},
				end: {
					scale: 1,
					display: "block",
					pointerEvents: "all",
					color: "#D9DEE4",
				},
			},
			type: this._pointerAnimation,
			initial: "initial",
		};
	}

	get borderGradientAnimation() {
		const variants: Variants = {
			initial: {
				background: gradient,
				"--border-gradient-deg-start": "0deg",
				"--border-gradient-deg-warning": "0deg",
				"--border-gradient-deg-error": "0deg",
			} as Variant,
			blur: {
				filter: "blur(2px)",
				scale: 1,
			},
			endBlur: {
				filter: "blur(0px)",
				scale: 1,
			},
			start: {
				"--border-gradient-deg-start": "1turn",
				background:
					"conic-gradient(from 0deg at 50% 50%, #D9DEE4 0deg, #D9DEE4 var(--border-gradient-deg-start), #777A7D var(--border-gradient-deg-start), #777A7D 360deg)",
				transition: {
					"--border-gradient-deg-start": {
						duration: 5,
					},
				},
			} as Variant,

			warning: {
				"--border-gradient-deg-warning": "1turn",
				background:
					"conic-gradient(from 0deg at 50% 50%, #CC4632 0deg, #CC4632 var(--border-gradient-deg-warning), #FF583E var(--border-gradient-deg-warning), #FF583E 360deg)",
				transition: {
					"--border-gradient-deg-warning": {
						duration: 2.5,
					},
				},
			} as Variant,
			error: {
				"--border-gradient-deg-error": "1turn",
				background:
					"conic-gradient(from 0deg at 50% 50%, #661104 0deg, #661104 var(--border-gradient-deg-error), #CC2308 var(--border-gradient-deg-error), #CC2308 360deg)",
				transition: {
					"--border-gradient-deg-error": {
						duration: 4,
					},
				},
			} as Variant,
		};

		return {
			variants: variants,
			initial: "initial",
			type: this._borderGradientAnimation,
		};
	}

	onChange(params: IParams) {
		this._params = params;

		if (params.prizeIndex === null) {
			this.setDefaultAnimations();
			return;
		}

		if (params.prizeIndex !== -1) {
			this._prize = params.prizeIndex;
			this._animationFlow = flowResult(this.runSpinnerAnimation());
			this._animationFlow.catch((reason) => {
				const error = reason as Error;
				if (error.message === "FLOW_CANCELLED") {
					//ignore the error as it's expected
				}
			});
		}
	}

	async init(params: IParams) {
		this._params = params;
		await Timings.beforeWheelInit();

		runInAction(() => {
			this._spinnerAnimation = "show";
			this._lightsAnimation = "show";
		});
	}

	@flow
	private *runSpinnerAnimation() {
		yield Timings.beforeWheelSpin();

		this._spinnerAnimation = "start";
		this._lightsAnimation = "start";
		this._pointerAnimation = "start";
		this._segmentsAnimation = "blur";
		this._borderGradientAnimation = "blur";

		yield Timings.wheelAnimation();

		this._segmentsAnimation = "endBlur";
		this._borderGradientAnimation = "endBlur";

		yield Timings.onResultSliceAnimation();
		yield Timings.afterResultDelay();

		if (this._params.prizeOption?.type === SliceType.WIN) {
			this._resultAnimation = "start";
		}
	}

	get hordeWidth() {
		const size = this._params.size || 1;
		if (size === 2) {
			return 2 * this._params.radius + 1;
		}

		return 2 * this._params.radius * Math.tan(Math.PI / size) + 1;
	}

	private get turnsPerItem() {
		return 360 / this._params.size;
	}

	private get offset() {
		// shift wheel to be between two segments
		return (-1 * this.turnsPerItem) / 2;
	}

	private get prizeAnimationOffset() {
		return -this.turnsPerItem * (this._prize || 0) + 360 * 7 - this.turnsPerItem;
	}

	private setDefaultAnimations() {
		this._animationFlow?.cancel();
		this._spinnerAnimation = "show";
		this._segmentsAnimation = "initial";
		this._resultAnimation = "initial";
		this._pointerAnimation = "initial";
		this._borderGradientAnimation = "initial";
	}
}
