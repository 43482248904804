import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IHttpClientService} from "data/services/http";
import {Locale, Language} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, runInAction} from "mobx";
import type {IWheelsStore} from "data/stores/wheels/wheels.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {ILiveUpdatesStore} from "data/stores/live_updates/live_updates.store";
import type {IUserStore} from "data/stores/user/user.store";
import {LanguageStorage} from "data/utils/languageStorage";

export interface IBootstrapController extends ViewController {
	get isReady(): boolean;
}

@injectable()
export class BootstrapController implements IBootstrapController {
	private _isReady = false;

	constructor(
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService,
		@inject(Bindings.WheelsStore) private _wheelStore: IWheelsStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LiveUpdatesStore) private _liveUpdatesStore: ILiveUpdatesStore
	) {
		makeAutoObservable(this);
	}

	get isReady(): boolean {
		return this._isReady;
	}

	/**
	 * The method is to define a user's locale. It can be done by:
	 * 1) navigator.language
	 * 2) Site URL
	 * 3) Some JSON or API request settings
	 * 4) Whatever else
	 */
	private async defineLocale(): Promise<Locale> {
		const usersLocale = LanguageStorage.get() || ("" as Locale);

		if (this.isSupportedLocaleByLang(usersLocale)) {
			return usersLocale;
		}

		return Promise.resolve(this.navigatorLanguage);
	}

	private get navigatorLanguage() {
		if (navigator.language.includes(Language.FR)) {
			return Locale.FR_FR;
		}

		return Locale.EN_US;
	}

	public isSupportedLocaleByLang(locale: string) {
		const supportedLanguages = [Language.EN, Language.FR];

		return supportedLanguages.some((it) =>
			String(locale).toLowerCase().includes(it.toLowerCase())
		);
	}

	async init() {
		await this.fetchData();
		await this.i18nInit();

		runInAction(() => {
			this._isReady = true;
		});

		this._liveUpdatesStore.subscribe({
			events: async () => {
				await this._eventsStore.fetch();
				if (this._userStore.user) {
					await this._userStore.requestUser();
				}
			},
			wheels: () => this._wheelStore.fetch(),
		});
	}

	private async fetchData() {
		try {
			await Promise.all([this._wheelStore.fetch(), this._eventsStore.fetch()]);
		} catch (e) {
			// Show error message to a user
		}
	}
	private async i18nInit() {
		try {
			await this._i18nStore.switchLocale({
				locale: await this.defineLocale(),
			});
		} catch (_err) {
			// Show error message to a user
		}

		// Set locale that will be appended to each request
		this._apiHTTPClient.setLocale(this._i18nStore.lang);
	}

	dispose() {
		this._liveUpdatesStore.unsubscribe();
	}
}
