import {PROJECT_NAME} from "data/constants";
import {Locale} from "data/enums";

export abstract class LanguageStorage {
	private static _key = `${PROJECT_NAME}-language`;
	static save(locale: Locale) {
		localStorage.setItem(LanguageStorage._key, locale);
	}

	static get() {
		return localStorage.getItem(LanguageStorage._key) as Locale | null;
	}
}
