export const API_URL = process.env.REACT_APP_API_URL || "";
export const ENV = process.env.REACT_APP_ENVIRONMENT || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\\-!@#$%^&*\\(\\)_+\\|~=`\\{\\}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_APP_ID || false;
