export enum RequestState {
	IDLE = "IDLE",
	PENDING = "PENDING",
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
}

export enum ModalType {
	ERROR,
	CONFIRM,
	LOGIN,
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
	FR_FR = "fr-fr",
}

export enum Language {
	EN = "en",
	FR = "fr",
}

export enum PostMessageType {
	WHEEL = "wheel",
	RESET = "reset",
}
