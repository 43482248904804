import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable, runInAction, reaction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {type IEventsStore, IEvent, EventStatus} from "data/stores/events/events.store";
import {
	type IWheelsStore,
	IWheel,
	ISlice,
	WheelStatus,
	SliceType,
	StyleHeader,
	WheeType,
} from "data/stores/wheels/wheels.store";
import type {ISpinsStore} from "data/stores/wheel/spins.store";
import {extractErrorMessage, wait} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IUserStore} from "data/stores/user/user.store";
import {shuffle, times, first, over, defaultTo} from "lodash";
import {IAnimation} from "data/types/animation";
import {Variants} from "framer-motion";
import {Timings} from "data/utils/timings";
import {captureSentryError} from "data/utils/sentry";

const isDebug = false;

const visibilityAnimationVariants: Variants = {
	initial: {
		scale: 0,
		opacity: 0,
	},
	hide: {
		scale: 0,
		opacity: 0,
		transition: {
			duration: 0.5,
		},
		transitionEnd: {
			maxHeight: 0,
		},
	},
	show: {
		opacity: 1,
		scale: 1,
		maxHeight: "100%",
		transition: {
			maxHeight: {
				duration: 0,
			},
		},
	},
};

export interface ILandingController extends ViewController {
	readonly i18n: ILocalizationStore;
	onSpinClick: () => void;
	get isLoggedIn(): boolean;
	get isLoading(): boolean;
	get hasSpunWheel(): boolean;
	get wheelState(): RequestState;
	get wheelSlices(): IWheel["slices"];
	get hasWheel(): boolean;
	get prize(): ISlice | undefined;
	get prizeId(): number | null;
	get currentEvent(): IEvent | undefined;
	get nextEvent(): IEvent | undefined;
	get landingSlices(): IWheel["slices"] | null;
	get resultAreaAnimation(): IAnimation;
	get wheelAreaAnimation(): IAnimation;
	get ridersAnimation(): IAnimation;
}

@injectable()
export class LandingController implements ILandingController {
	@observable _disposers: ReturnType<typeof reaction>[] = [];
	@observable _resultAreaAnimation = "initial";
	@observable _wheelAreaAnimation = "initial";
	@observable _ridersAnimation = "hide";

	@observable private _wheelState: RequestState = RequestState.IDLE;
	@observable private _requestSpinState: RequestState = RequestState.IDLE;
	@observable _hasSpunTheWheel = false;
	@observable _isLoading = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.WheelsStore) private _wheelStore: IWheelsStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.SpinsStore) private _spinsStore: ISpinsStore
	) {
		makeAutoObservable(this);
	}

	get isLoggedIn(): boolean {
		return this._userStore.isAuthorized;
	}

	get userType(): WheeType | null {
		return this._wheelStore.wheelUserType;
	}

	get isLoading() {
		return this._requestSpinState === RequestState.PENDING;
	}

	/**
	 * Used to show different copy in the wheel header depends on wheelState
	 */
	get hasSpunWheel() {
		return this._hasSpunTheWheel;
	}

	/**
	 * Used to show different copy in the wheel header
	 */
	get wheelState() {
		return this._wheelState;
	}

	get wheelSlices(): ISlice[] {
		if (this.isLoggedIn) {
			return this.currentWheel?.slices || [];
		}

		/**
		 * Show slices for landing
		 */
		return this.landingSlices || [];
	}

	get hasWheel(): boolean {
		return !!this.currentWheel?.slices.length;
	}

	get prize(): ISlice | undefined {
		return this.wheelSlices.find((it) => it.id === this.prizeId);
	}

	get spin() {
		return this._spinsStore.currentSpin;
	}

	get prizeId(): number | null {
		return this.spin?.sliceId || null;
	}

	get currentEvent() {
		return this._eventsStore.currentEvent;
	}

	get nextEvent() {
		return first(this._eventsStore.scheduledEvents);
	}

	get currentWheel() {
		const event = this._eventsStore.currentEvent;
		const user = this._userStore.user;
		const userType = user?.isLocal ? WheeType.Local : WheeType.Global;

		if (!event || event?.status !== EventStatus.ACTIVE) {
			return null;
		}

		return this._wheelStore.list.find(
			(it) =>
				it.eventId === event.id && it.status === WheelStatus.ACTIVE && userType === it.type
		);
	}

	/**
	 * Returns 6 landing slices with only one visible.
	 * The visible one is called "Top Prize" - values are taken from current event
	 */
	get landingSlices() {
		const event = this.currentEvent;
		const topPrize = event?.topPrize;
		const SLICE_COUNT = 6;
		if (event && topPrize) {
			return times<ISlice>(SLICE_COUNT, (i) => {
				const defaultSlice = {
					id: i,
					position: i,
					name: `${i}`,
					type: SliceType.WIN,
					prize: {
						amount: 0,
						image: null,
					},
					header: {
						text: "",
						style: StyleHeader.NORMAL,
						size: "20px",
						color: "#000",
					},
					subheader: {
						text: "",
						style: StyleHeader.NORMAL,
						size: "20px",
						color: "#000",
					},
					color: i % 2 ? "#0079C9" : "#001433",
					chance: 0,
					expiryDate: "",
					isExpired: false,
				} as ISlice;
				if (i === SLICE_COUNT - 1) {
					return {
						...defaultSlice,
						prize: {
							...defaultSlice.prize,
							image: topPrize.image,
						},
						header: {
							text: this.i18n.takeTranslation(event, "topPrize.header.text"),
							style: defaultTo(topPrize.header?.style, StyleHeader.NORMAL),
							size: defaultTo(topPrize.header?.size, "10px"),
							color: defaultTo(topPrize.header?.color, "#fff"),
						},
						subheader: {
							text: this.i18n.takeTranslation(event, "topPrize.subheader.text"),
							style: defaultTo(topPrize.subheader?.style, StyleHeader.NORMAL),
							size: defaultTo(topPrize.subheader?.size, "14px"),
							color: defaultTo(topPrize.subheader?.color, "#fff"),
						},
					} as ISlice;
				}
				return defaultSlice;
			});
		}
		return null;
	}

	/**
	 * Performs action on the button. Opens the login modal or the wheel logic.
	 */
	onSpinClick = () => {
		const currentWheel = this.currentWheel;
		const isAuthorized = this.isLoggedIn;

		if (currentWheel) {
			/**
			 * isDebug - run animation without authorization.
			 */
			if (isAuthorized || isDebug) {
				void this.onSpin(currentWheel.id);
			} else {
				this._modalsStore.showModal(ModalType.LOGIN, {
					message: "",
				});
			}
		}
	};

	dispose() {
		this._spinsStore.clear();
		over(this._disposers)();
	}

	init() {
		this._disposers = [
			reaction(
				() => this.userType,
				(userWheelType) => {
					if (userWheelType) {
						this.hideRiders();
						void this._fetchSpin();
					} else {
						this.onReady();
						this.showRiders();
					}
				},
				{fireImmediately: true}
			),
			/**
			 * Reset the wheel if the current event has been completed
			 */
			reaction(
				() => this.currentEvent,
				(next, prev) => {
					if (prev && next?.id !== prev.id) {
						this.onReset();
					}
				},
				{fireImmediately: true}
			),
		];
	}

	private onSpin = async (wheelId: number) => {
		if (this._isLoading) {
			return;
		}

		this._isLoading = true;

		await wait(500);

		runInAction(() => {
			this._wheelState = RequestState.PENDING;
		});
		/**
		 * isDebug to run spinner animation without any requests to BE side.
		 */
		if (isDebug) {
			runInAction(() => {
				this._spinsStore.setCurrentSpin({
					id: 1,
					wheelId: wheelId,
					sliceId:
						shuffle(this.wheelSlices).find((it) => it.type === SliceType.SPIN_AGAIN)
							?.id || 0,
				});
			});

			await Timings.wheelAnimationDelay();

			runInAction(() => {
				this._wheelState = RequestState.SUCCESS;
			});

			await Timings.onResultSliceAnimation();
			void this.onComplete();
			return;
		}
		try {
			await this._spinsStore.spin(wheelId);

			await Timings.wheelAnimationDelay();

			runInAction(() => {
				this._wheelState = RequestState.SUCCESS;
			});

			await Timings.onResultSliceAnimation();
			void this.onComplete();
		} catch (e) {
			runInAction(() => {
				this._spinsStore.clear();
				this._wheelState = RequestState.ERROR;
			});
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});

			captureSentryError("LandingController", e, {
				wheelId,
				user: this._userStore.user,
			});
		}
	};

	private async _fetchSpin() {
		const wheelId = this.currentWheel?.id;

		/**
		 * isDebug - do not fetch spins in debug mode
		 */
		if (!wheelId || isDebug) {
			runInAction(() => {
				this._requestSpinState = RequestState.SUCCESS;
			});

			this.onReady();
			return;
		}

		runInAction(() => {
			this._requestSpinState = RequestState.PENDING;
		});

		try {
			const result = await this._spinsStore.fetchSpin(wheelId);
			runInAction(() => {
				this._requestSpinState = RequestState.SUCCESS;
				if (result?.sliceId) {
					/**
					 * Show result animation
					 */
					void this.onShowResult();
				} else {
					this.onReady();
				}
			});
		} catch (e) {
			runInAction(() => {
				this._requestSpinState = RequestState.ERROR;
				this._wheelState = RequestState.ERROR;
			});
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});
			captureSentryError("LandingController", e, {
				wheelId,
				user: this._userStore.user,
			});
		}
	}

	private async onComplete() {
		runInAction(() => {
			this._wheelState = RequestState.SUCCESS;
		});

		/**
		 * Resets the spinner to default view.
		 */
		if (this.prize?.type === SliceType.SPIN_AGAIN) {
			this.onReset();
			return;
		}

		/**
		 * SliceType.WIN has additional animation within the spinner and requires more time
		 */
		if (this.prize?.type === SliceType.WIN) {
			//wait for result animation
			await Timings.onResultSliceAnimation();
		}

		runInAction(() => {
			this._wheelAreaAnimation = "hide";
		});

		await Timings.afterResultDelay();

		runInAction(() => {
			this._hasSpunTheWheel = true;
			this._resultAreaAnimation = "show";
			this._isLoading = false;
		});
	}

	private onReady() {
		this._hasSpunTheWheel = false;
		this._wheelState = RequestState.IDLE;
		this._wheelAreaAnimation = "show";
		this._resultAreaAnimation = "hide";
		this._isLoading = false;
	}

	private async onShowResult() {
		runInAction(() => {
			this._wheelAreaAnimation = "hide";
			this._hasSpunTheWheel = true;
			this._wheelState = RequestState.SUCCESS;
		});

		await Timings.afterResultDelay();
		runInAction(() => {
			this._resultAreaAnimation = "show";
		});
	}

	private onReset() {
		this.onReady();
		this._spinsStore.clear();
	}

	get resultAreaAnimation(): IAnimation {
		return {
			variants: visibilityAnimationVariants,
			type: this._resultAreaAnimation,
			initial: "initial",
		};
	}

	get wheelAreaAnimation(): IAnimation {
		return {
			variants: visibilityAnimationVariants,
			type: this._wheelAreaAnimation,
			initial: "initial",
		};
	}

	get ridersAnimation(): IAnimation {
		return {
			variants: {
				show: {
					scale: 1,
					opacity: 1,
					pointerEvents: "none",
				},
				hide: {
					opacity: 0,
					scale: 0,
				},
			},
			type: this._ridersAnimation,
			initial: "hide",
		};
	}

	private hideRiders() {
		this._ridersAnimation = "hide";
	}

	private showRiders() {
		this._ridersAnimation = "show";
	}
}
