/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";
import {buttonClasses} from "@mui/material/Button";
import backgroundButtons from "assets/img/button_background.svg";

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					borderRadius: 0,
					fontWeight: 700,
					fontSize: "16px",
				},
				contained: {
					[`&.${buttonClasses.colorSecondary}`]: {
						background: "#000",
						color: "#fff",
					},
					[`&.${buttonClasses.colorPrimary}`]: {
						background: `url(${backgroundButtons}) left center`,
						backgroundRepeat: "no-repeat",
						color: "#fff",
						border: "1px solid #FFF",
						"&.disabled": {
							opacity: 0.3,
						},
					},
				},
			},
		},

		MuiIconButton: {
			styleOverrides: {
				colorSecondary: {
					color: "#000",
				},
				colorInfo: {
					color: "#fff",
				},
			},
		},
	},
};
