export const Bindings = {
	AuthController: Symbol("AuthController"),
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	LandingController: Symbol("LandingController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	LiveUpdatesStore: Symbol("LiveUpdatesStore"),
	SpinnerController: Symbol("SpinnerController"),
	ModalLoginController: Symbol("ModalLoginController"),
	HeaderController: Symbol("HeaderController"),
	ContentController: Symbol("ContentController"),
	EventsStore: Symbol("EventsStore"),
	WheelsStore: Symbol("WheelsStore"),
	SpinProvider: Symbol("SpinProvider"),
	SpinsStore: Symbol("SpinsStore"),
	ResultController: Symbol("ResultController"),
	RacesListController: Symbol("RacesListController"),
	RaceHeaderController: Symbol("RaceHeaderController"),
	RaceCardTimerController: Symbol("RaceCardTimerController"),
	BrandLogoController: Symbol("BrandLogoController"),
	PreviewController: Symbol("PreviewController"),
	PostMessageStore: Symbol("PostMessageStore"),
	PostMessageService: Symbol("PostMessageService"),
};
