import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import type {IChecksums, IChecksumStore} from "data/stores/checksum/checksum.store";
import {forEach} from "lodash";

/**
 * Constant for determine update frequency.
 */
const LIVE_UPDATES_FETCH_TIMING = 1000 * 60;

type IChecksumActions = Record<keyof IChecksums, () => Promise<void>>;

export interface ILiveUpdatesStore {
	subscribe: (actions: IChecksumActions, interval?: number) => void;
	unsubscribe: () => void;
}

/**
 * This store is responsible for data live updates, and relies on ChecksumStore.
 * How to use it:
 *
 * By default, the checksums request interval is 1 minute
 * this._liveUpdatesStore.subscribe({
 * 		// where key is a field from checksums.json and,
 * 		// value is a callback that will fire once checksum of this filed is changed
 * 		rounds: this.fetchRounds,
 * });
 *
 * But you can adjust interval for any other value
 * this._liveUpdatesStore.subscribe({
 * 		rounds: this.fetchRounds,
 * }, 15000);
 *
 * Passed actions won't be called during first checksums.json fetch.
 * It means first call of actions may happen only after the interval become passed the first time.
 *
 * If the ChecksumsStore is created as a factory, and not singleton,
 * then each subscribe action will behave as a separate action and not depend on each other.
 *
 * Please don't forget to call
 * this._liveUpdatesStore.unsubscribe();
 * to reduce battery consuming and decrease memory usage once polling checksums isn't required anymore.
 */
@injectable()
export class LiveUpdatesStore implements ILiveUpdatesStore {
	@observable private _actions: IChecksumActions = {};
	@observable private _interval?: ReturnType<typeof setInterval>;

	constructor(@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore) {
		makeAutoObservable(this);
	}

	@action
	public subscribe(actions: IChecksumActions, interval: number = LIVE_UPDATES_FETCH_TIMING) {
		this.unsubscribe();

		this._actions = actions;
		void this._checksumStore.fetchChecksums();

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums().then(() => {
				this.invokeActionsOfChangedChecksum();
			});
		}, interval);
	}

	/**
	 * Stop listening for a checksums changes and invoke actions.
	 * You should call it at the end of the game match/round/etc.
	 */
	@action
	public unsubscribe() {
		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	@action
	invokeActionsOfChangedChecksum() {
		forEach(this._checksumStore.changedChecksums, (_, key) => {
			const action = this._actions[key];

			if (action && typeof action === "function") {
				void action();
			}
		});
	}
}
