import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {BootstrapRoute} from "views/components/route/bootstrap_route.component";

const Home = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const Preview = lazy(retryFailLoad(() => import("views/pages/preview/preview.page")));

const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route path="/" element={<BootstrapRoute />}>
			<Route index={true} element={<Home />} />
			<Route path="help/*" element={<Help />} />
		</Route>
		<Route path="/preview" element={<Preview />} />
		<Route path="components" element={<ComponentsReview />} />
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default RootRoutes;
