import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";

import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";

import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";

import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";

import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";

import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	type ILiveUpdatesStore,
	LiveUpdatesStore,
} from "data/stores/live_updates/live_updates.store";
import {
	ISpinnerController,
	SpinnerController,
} from "views/components/spinner_framer/spinner.controller";
import {
	type IModalLoginController,
	ModalLoginController,
} from "views/components/modals/modal_login/modal_login.controller";
import {IAuthController, AuthController} from "views/controllers/auth/auth.controller";
import {type IHeaderController, HeaderController} from "views/components/header/header.controller";
import {type IEventsStore, EventsStore} from "data/stores/events/events.store";
import {type IWheelsStore, WheelsStore} from "data/stores/wheels/wheels.store";
import {type ISpinProvider, SpinProvider} from "data/providers/spin/spin.provider";
import {type ISpinsStore, SpinsStore} from "data/stores/wheel/spins.store";
import {type IResultController, ResultController} from "views/components/result/result.controller";
import {IContentController, ContentController} from "views/components/content/content.controller";
import {
	type IRacesListController,
	RacesListController,
} from "views/components/races_list/races_list.controller";
import {
	type IRaceHeaderController,
	RaceHeaderController,
} from "views/components/race_header/race_header.controller";
import {
	type IRaceCardTimerController,
	RaceCardTimerController,
} from "views/components/race_card_timer/race_card_timer.controller";
import {
	type IBrandLogoController,
	BrandLogoController,
} from "views/components/brand_logo/brand_logo.controller";
import {
	ContactUsController,
	IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {type IPreviewController, PreviewController} from "views/pages/preview/preview.controller";
import {
	type IPostMessageStore,
	PostMessageStore,
} from "data/stores/post_message/post_message.store";
import {
	IPostMessageService,
	PostMessageService,
} from "data/services/postMessage/postMessage.service";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);

	bind<IPostMessageService>(Bindings.PostMessageService)
		.to(PostMessageService)
		.inSingletonScope();
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<ISpinProvider>(Bindings.SpinProvider).to(SpinProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILiveUpdatesStore>(Bindings.LiveUpdatesStore).to(LiveUpdatesStore);
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IEventsStore>(Bindings.EventsStore).to(EventsStore).inSingletonScope();
	bind<IWheelsStore>(Bindings.WheelsStore).to(WheelsStore).inSingletonScope();
	bind<ISpinsStore>(Bindings.SpinsStore).to(SpinsStore).inSingletonScope();
	bind<IPostMessageStore>(Bindings.PostMessageStore).to(PostMessageStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<ISpinnerController>(Bindings.SpinnerController).to(SpinnerController);
	bind<IModalLoginController>(Bindings.ModalLoginController).to(ModalLoginController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IResultController>(Bindings.ResultController).to(ResultController);
	bind<IContentController>(Bindings.ContentController).to(ContentController);
	bind<IRacesListController>(Bindings.RacesListController).to(RacesListController);
	bind<IRaceHeaderController>(Bindings.RaceHeaderController).to(RaceHeaderController);
	bind<IRaceCardTimerController>(Bindings.RaceCardTimerController).to(RaceCardTimerController);
	bind<IBrandLogoController>(Bindings.BrandLogoController).to(BrandLogoController);
	bind<IPreviewController>(Bindings.PreviewController).to(PreviewController);
});
