import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
	IStaticContentPayload,
} from "data/stores/static_content/static_content.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";

import {IEvent} from "data/stores/events/events.store";
import {IWheel} from "data/stores/wheels/wheels.store";

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;
	wheels(): Promise<AxiosResponse<{wheels: IWheel[]}>>;
	events(): Promise<AxiosResponse<{events: IEvent[]}>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;

	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(params: IStaticContentPayload): Promise<AxiosResponse<ICategories>>;
	helpSections(params: IStaticContentPayload): Promise<AxiosResponse<ISections>>;
	helpArticles(params: IStaticContentPayload): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	wheels = () => this._jsonClient.get<{wheels: IWheel[]}>("wheels.json");
	events = () => this._jsonClient.get<{events: IEvent[]}>("events.json");
	// wheels = () => new JSONProviderMock().wheels();
	// events = () => new JSONProviderMock().events();
	checksums = () => this._jsonClient.get<IChecksums>("checksums.json");
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`loco/${locale}.json`);

	helpCategories = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`, params);
	helpSections = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`, params);
	helpArticles = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`, params);
}
