import {wait} from "data/utils/index";

export abstract class Timings {
	static async beforeWheelInit() {
		await wait(500);
	}

	static async beforeWheelSpin() {
		await wait(1000);
	}

	static async wheelAnimation() {
		await wait(4000);
	}

	static async onResultSliceAnimation() {
		await wait(5000);
	}

	static async afterResultDelay() {
		await wait(500);
	}

	static async wheelAnimationDelay() {
		await Timings.beforeWheelSpin();
		await Timings.wheelAnimation();
	}
}
