import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalLoginController} from "views/components/modals/modal_login/modal_login.controller";
import {
	Button,
	Typography,
	Stack,
	Modal,
	FormControl,
	TextField,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
	FormHelperText,
	FormLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";
import styled from "@emotion/styled";
import {ErrorText} from "views/components/form";
import {ReactComponent as Logo} from "assets/img/logo.svg";
import {ReactComponent as SpinToWinLogo} from "assets/img/spin_to_win_logo_dark.svg";
import {NavLink} from "react-router-dom";
import {Hidden} from "views/components/hidden/hidden.component";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 320px;
	width: 100%;
	background: #fff;
	padding: 16px;
	border-radius: 8px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
	}

	form {
		padding: 0 4px 4px;
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button {
		border-radius: 50%;
		padding: 0;
		width: 24px;
		height: 24px;

		svg {
			width: 16px;
			height: 16px;
		}
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;

	svg {
		width: 100%;
		height: auto;
	}
`;

const SecondLogo = styled.div`
	max-width: 300px;
	margin: 0 auto;
	svg {
		width: 100%;
		height: auto;
	}
`;

export const ModalLogin: React.FC = observer(() => {
	const {
		isOpen,
		handleFormSubmit,
		handleFormOnChange,
		error,
		i18n,
		isFormDisabled,
		close,
		isValid,
		isFirstStep,
		currentEvent,
	} = useViewController<IModalLoginController>(Bindings.ModalLoginController);

	const labelEmail = i18n.t("data_capture.form.email_label", "Email Address");

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button
						sx={{minWidth: 0}}
						onClick={close}
						variant={"contained"}
						color={"secondary"}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<form onSubmit={handleFormSubmit} onChange={handleFormOnChange}>
					<Stack gap={2} alignItems={"center"}>
						<SecondLogo>
							<SpinToWinLogo />
						</SecondLogo>
						<LogoWrapper>
							<Logo />
						</LogoWrapper>

						<Hidden when={!isFirstStep}>
							<Stack gap={2} width={"100%"}>
								<Stack gap={1}>
									<Typography variant={"h4"}>
										{i18n.t("data_capture.form.header", "Get Started")}
									</Typography>
									<Typography variant={"body1"}>
										{i18n.t(
											"data_capture.form.email_title",
											"Enter your email so we can send you your prize"
										)}
									</Typography>
								</Stack>
								<FormControl fullWidth>
									<TextField
										disabled={isFormDisabled}
										label={labelEmail}
										name="email"
										type="email"
										variant={"filled"}
										fullWidth={true}
										placeholder={labelEmail}
										required={true}
										error={Boolean(error?.email)}
										helperText={error?.email}
									/>
								</FormControl>

								<Stack gap={0.5}>
									<FormControlLabel
										disableTypography={true}
										sx={{
											textAlign: "left",
										}}
										control={<Checkbox name={"terms"} required={true} />}
										label={
											<Typography variant={"body1"} component={"span"}>
												{i18n.t(
													"data_capture.form.terms_part_one",
													"I have read and accepted the"
												)}{" "}
												<NavLink
													to={"/help/terms-and-conditions"}
													target={"_blank"}>
													<Typography
														variant={"body1"}
														fontWeight={700}
														component={"span"}>
														{i18n.t(
															"data_capture.form.terms_part_two",
															"Terms & Conditions"
														)}
													</Typography>
												</NavLink>
											</Typography>
										}
									/>
									<FormControlLabel
										disableTypography={true}
										sx={{
											textAlign: "left",
										}}
										control={<Checkbox name={"marketing"} />}
										label={
											<Typography variant={"body1"}>
												{i18n.t(
													"data_capture.form.marketing",
													"I wish to receive email and marketing."
												)}
											</Typography>
										}
									/>
								</Stack>

								<FormControl
									fullWidth
									sx={{
										flex: 1,
										alignItems: "center",
									}}>
									<Exist when={error?.common}>
										<ErrorText>{error?.common}</ErrorText>
									</Exist>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										size="large"
										className={isFormDisabled || !isValid ? "disabled" : ""}>
										{i18n.t("data_capture.form.submit", "spin the wheel")}
									</Button>
								</FormControl>
							</Stack>
						</Hidden>

						<Hidden when={isFirstStep}>
							<Stack gap={2} width={"100%"}>
								<Stack gap={1}>
									<Typography variant={"h4"}>
										{i18n.t(
											"data_capture.before_spin.header",
											"Before you spin"
										)}
									</Typography>
								</Stack>
								{currentEvent?.question ? (
									<FormControl fullWidth={true} error={!!error?.eventOptionId}>
										<FormLabel
											focused={false}
											id="question"
											sx={{
												color: "#000",
												paddingBottom: "16px",
												borderBottom: "1px solid #9E9E9E",
											}}
											error={false}>
											{currentEvent.question}
										</FormLabel>
										<RadioGroup aria-labelledby="question" name="eventOptionId">
											{currentEvent.options.map((option) => (
												<FormControlLabel
													key={option.id}
													value={option.id}
													control={<Radio />}
													label={i18n.takeTranslation(option, "name")}
												/>
											))}
										</RadioGroup>
										<Exist when={error?.eventOptionId}>
											<FormHelperText>{error?.eventOptionId}</FormHelperText>
										</Exist>
									</FormControl>
								) : (
									<ErrorText>
										{i18n.t("data_capture.before_spin.no_event", "no_event")}
									</ErrorText>
								)}

								<FormControl
									fullWidth
									sx={{
										flex: 1,
										alignItems: "center",
									}}>
									<Exist when={error?.common}>
										<ErrorText>{error?.common}</ErrorText>
									</Exist>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										size="large"
										className={isFormDisabled || !isValid ? "disabled" : ""}>
										{i18n.t("data_capture.form.submit", "spin the wheel")}
									</Button>
								</FormControl>
							</Stack>
						</Hidden>
					</Stack>
				</form>
			</ModalContent>
		</Modal>
	);
});
