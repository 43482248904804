/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

export const componentsTheme: ThemeOptions = {
	components: {
		MuiIcon: {
			defaultProps: {
				color: "inherit",
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: "#AEAEB2",
					fontSize: "12px",
					fontWeight: 400,
					lineHeight: "20px",
					letterSpacing: "0.4px",
				},
			},
		},

		MuiTab: {
			styleOverrides: {
				root: {
					background: "#000",
					color: "#fff",
					fontFamily: "var(--fontFamilyBase)",
					fontWeight: 700,
					fontSize: "14px",
					"&.Mui-selected": {
						color: "#fff",
					},
				},
			},
		},
	},
};
