import React, {PropsWithChildren} from "react";
import {Box} from "@mui/material";

interface IProps {
	when?: boolean | null | number;
}

export const Hidden: React.FC<PropsWithChildren<IProps>> = ({when, children}) => {
	return (
		<Box
			sx={
				when
					? {
							visibility: "hidden",
							opacity: 0,
							pointerEvents: "none",
							position: "absolute",
						}
					: {
							width: "100%",
						}
			}>
			{children}
		</Box>
	);
};
