import {makeAutoObservable, observable, action, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {first, last} from "lodash";
import {IStyledHeader, ITranslation} from "data/types/global";

export enum EventStatus {
	SCHEDULED = "scheduled",
	ACTIVE = "active",
	COMPLETE = "complete",
}

export interface IEvent {
	id: number;
	name: string;
	country: string;
	city: string | null;
	startDate: string;
	endDate: string;
	backgroundImage: string;
	flagImage: string;
	raceTrackImage: string;
	description: string;
	topPrize: ITopPrize;
	brand: IBrand;
	status: EventStatus;
	question: string;
	options: IOption[];
	translations: ITranslation[];
}

export interface IOption {
	id: number;
	position: number;
	name: string;
	translations: ITranslation[];
}

export interface IBrand {
	url: string;
	logo: string;
}

export interface ITopPrize {
	name: string;
	header: IStyledHeader;
	subheader: IStyledHeader;
	image: string;
}

export interface IEventsStore {
	get list(): IEvent[];

	get currentEvent(): IEvent | undefined;
	get activeEvent(): IEvent | undefined;
	get scheduledEvents(): IEvent[];
	get completedEvents(): IEvent[];

	fetch(): Promise<void>;
}

@injectable()
export class EventsStore implements IEventsStore {
	@observable private _list: IEvent[] = [];

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get list() {
		return this._list;
	}

	get currentEvent() {
		return this.activeEvent || first(this.scheduledEvents) || last(this.completedEvents);
	}

	get activeEvent() {
		return this.list.find((it) => it.status === EventStatus.ACTIVE);
	}

	get scheduledEvents() {
		return this.list.filter((it) => it.status === EventStatus.SCHEDULED);
	}

	get completedEvents() {
		return this.list.filter((it) => it.status === EventStatus.COMPLETE);
	}

	@action
	async fetch() {
		const {data} = await this._jsonProvider.events();

		runInAction(() => {
			this._list = data.events;
		});
	}
}
