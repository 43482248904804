import {makeAutoObservable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {IWheel} from "data/stores/wheels/wheels.store";
import {Bindings} from "data/constants/bindings";
import type {IPostMessageService} from "data/services/postMessage/postMessage.service";
import type {IPostMessageStore} from "data/stores/post_message/post_message.store";
import {IPostMessage} from "data/types/global";
import {PostMessageType} from "data/enums";

export interface IPreviewController extends ViewController {
	get wheelSlices(): IWheel["slices"];
}

@injectable()
export class PreviewController implements IPreviewController {
	private _wheel: IWheel | null = null;
	private _disposers: ReturnType<typeof reaction>[] = [];

	constructor(
		@inject(Bindings.PostMessageService) private _postMessageService: IPostMessageService,
		@inject(Bindings.PostMessageStore) private _postMessageStore: IPostMessageStore
	) {
		makeAutoObservable(this);
	}

	get wheel(): IWheel | null {
		return this._wheel;
	}

	get wheelSlices(): IWheel["slices"] {
		return this.wheel?.slices || [];
	}

	init() {
		this._postMessageService.startListening();
		this._postMessageService.sendMessageToParent("ready");

		this._disposers.push(
			reaction(
				() => this._postMessageStore.messages,
				(messages) => {
					messages.forEach((message, index) => {
						runInAction(() => {
							try {
								const data = JSON.parse(message) as IPostMessage<IWheel>;
								if (data.type === PostMessageType.WHEEL) {
									this.initWheel(data.data);
								}
								if (data.type === PostMessageType.RESET) {
									this._wheel = null;
									this._postMessageStore.clearMessages();
								}
								this._postMessageStore.removeMessage(index);
							} catch (e) {
								console.log(e);
							}
						});
					});
				}
			)
		);
	}

	private initWheel(wheel: IWheel) {
		this._wheel = wheel;
	}

	dispose() {
		this._postMessageService.stopListening();
		this._disposers.forEach((dispose) => dispose());
	}
}
