import {makeAutoObservable, action, runInAction, observable} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {IStyledHeader, ITranslation} from "data/types/global";

export enum WheelStatus {
	DRAFT = "draft",
	SCHEDULED = "scheduled",
	ACTIVE = "active",
	COMPLETE = "complete",
}

export enum WheeType {
	Global = "global",
	Local = "local",
}

export enum StyleHeader {
	NORMAL = "normal",
	ITALIC = "italic",
}

export enum SliceType {
	NO_WIN = "no_win",
	WIN = "win",
	SPIN_AGAIN = "spin_again",
}

export interface IWheel {
	id: number;
	eventId: number;
	type: WheeType;
	name: string;
	startDate: string;
	endDate: string;
	status: WheelStatus;
	slices: ISlice[];
	translations: ITranslation[];
}

export interface ISlice {
	id: number;
	position: number;
	name: number | string;
	type: SliceType;
	prize: IPrize;
	header: IStyledHeader;
	subheader: IStyledHeader;
	color: string;
	chance: number;
	expiryDate: string | null;
	isExpired: boolean;
	translations: ITranslation[];
}

export interface IPrize {
	amount: number;
	image: null | string;
}

export interface IWheelsStore {
	get wheelUserType(): WheeType | null;
	get list(): IWheel[];
	fetch(): Promise<void>;
	setUserWheelType(type: WheeType): void;
}

@injectable()
export class WheelsStore implements IWheelsStore {
	@observable private _list: IWheel[] = [];
	@observable private _userWheelType: WheeType | null = null;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get wheelUserType(): WheeType | null {
		return this._userWheelType;
	}

	get list() {
		if (this._userWheelType) {
			return this._list.filter((it) => it.type === this._userWheelType);
		}
		return this._list;
	}

	@action
	async fetch() {
		const {data} = await this._jsonProvider.wheels();

		runInAction(() => {
			this._list = data.wheels;
		});
	}

	setUserWheelType(type: WheeType) {
		this._userWheelType = type;
	}
}
