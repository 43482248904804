import {makeAutoObservable, observable, action, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILoginPayload} from "data/providers/api/auth.api.provider";
import React from "react";
import {WheeType, type IWheelsStore} from "data/stores/wheels/wheels.store";
import type {IEvent, IEventsStore} from "data/stores/events/events.store";
import {captureSentryError} from "data/utils/sentry";

interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	terms: HTMLInputElement;
	marketing: HTMLInputElement;
	eventOptionId: HTMLInputElement;
}

export interface IModalLoginController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;

	get isFirstStep(): boolean;

	get currentEvent(): IEvent | undefined;

	get error(): Record<string, string> | null;

	get isFormDisabled(): boolean;

	get isValid(): boolean;

	handleFormSubmit: (event: React.SyntheticEvent<ILoginForm>) => void;
	login: (params: ILoginPayload) => Promise<void>;
	handleFormOnChange: (event: React.ChangeEvent<ILoginForm>) => void;
	close: () => void;
}

@injectable()
export class ModalLoginController implements IModalLoginController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isValid: boolean = false;
	@observable private _isFirstStep: boolean = true;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.WheelsStore) private _wheelsStore: IWheelsStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get isValid(): boolean {
		return this._isValid;
	}

	get currentEvent() {
		return this._eventsStore.currentEvent;
	}

	get isFirstStep(): boolean {
		return this._isFirstStep;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.LOGIN;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action handleFormOnChange = (event: React.ChangeEvent<ILoginForm>) => {
		const {terms, email, eventOptionId} = event.currentTarget;

		if (this._isFirstStep) {
			this._isValid = terms.checked && email.validity.valid;
		} else {
			const answer = Number(eventOptionId.value);
			this._isValid = !isNaN(answer);
		}

		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this.reportError(extractErrorMessage(error));
	};

	@action
	async login(payload: ILoginPayload) {
		this._requestState = RequestState.PENDING;
		try {
			await this._userStore.login(payload);
			this._requestState = RequestState.SUCCESS;

			const user = this._userStore.user;

			runInAction(() => {
				this._wheelsStore.setUserWheelType(
					user?.isLocal ? WheeType.Local : WheeType.Global
				);
			});

			this._modalsStore.modalContent?.callback?.();
			this.close();
		} catch (e) {
			this._requestState = RequestState.ERROR;
			this.onError(e as AxiosError<IApiResponse>);
			captureSentryError("ModalLoginController", e, payload);
		}
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {email, marketing, eventOptionId} = event.currentTarget;

		const emailRegex = new RegExp(
			"[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?",
			"gm"
		);

		if (!emailRegex.exec(email.value)) {
			return this.reportError(
				this.i18n.t(
					"data_capture.form.email_invalid",
					"Please provide a valid email address"
				),
				"email"
			);
		}

		if (this._isFirstStep) {
			this._isValid = false;
			this._isFirstStep = false;
			return;
		}

		if (!eventOptionId.value.length) {
			return this.reportError(
				this.i18n.t("data_capture.form.select_answer", "Please select an answer"),
				"eventOptionId"
			);
		}

		void this.login({
			email: email.value,
			// terms: terms.checked,
			isOptIn: marketing.checked,
			eventOptionId: Number(eventOptionId.value),
		});
	};

	close = () => {
		this._modalsStore.hideModal();
		this._requestState = RequestState.IDLE;
		this._errorMsg = null;
		this._errorPlace = "";
		this._isValid = false;
		this._isFirstStep = true;
	};
}
