import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import type {IEvent, IEventsStore} from "data/stores/events/events.store";
import {Bindings} from "data/constants/bindings";

export interface IBrandLogoController extends ViewController {
	get brandLogo(): IEvent["brand"] | undefined;

	handleLogoError: () => void;
}

@injectable()
export class BrandLogoController implements IBrandLogoController {
	@observable private _hasLogoError = false;

	constructor(@inject(Bindings.EventsStore) public readonly _eventsStore: IEventsStore) {
		makeAutoObservable(this);
	}

	get brandLogo(): IEvent["brand"] | undefined {
		if (this._hasLogoError) {
			return;
		}
		const event = this._eventsStore.currentEvent;

		return event?.brand;
	}

	handleLogoError = () => {
		this._hasLogoError = true;
	};
}
