import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ISpinProvider} from "data/providers/spin/spin.provider";

export interface ISpin {
	id: number;
	wheelId: number;
	sliceId: number;
}

export interface ISpinsStore {
	get allSpins(): ISpin[];
	get currentSpin(): ISpin | null;

	spin(wheelId: number): Promise<ISpin>;
	fetchSpin(wheelId: number): Promise<ISpin>;

	fetchSpins(): Promise<void>;
	setCurrentSpin(spin: ISpin): void;

	clear: () => void;
}

@injectable()
export class SpinsStore implements ISpinsStore {
	@observable private _spins: ISpin[] = [];
	@observable _currentSpin: ISpin | null = null;

	get allSpins(): ISpin[] {
		return this._spins;
	}

	get currentSpin() {
		return this._currentSpin;
	}
	constructor(@inject(Bindings.SpinProvider) private _spinProvider: ISpinProvider) {
		makeAutoObservable(this);
	}

	async spin(wheelId: number): Promise<ISpin> {
		const response = await this._spinProvider.spin({wheelId}).post();
		runInAction(() => {
			this.setCurrentSpin(response.data.success.spin);
		});
		return response.data.success.spin;
	}

	async fetchSpin(wheelId: number): Promise<ISpin> {
		const response = await this._spinProvider.spin({wheelId}).get();
		runInAction(() => {
			this.setCurrentSpin(response.data.success.spin);
		});
		return response.data.success.spin;
	}

	async fetchSpins(): Promise<void> {
		const response = await this._spinProvider.spins().get();
		runInAction(() => {
			this._spins = response.data.success.spins;
		});
	}

	setCurrentSpin(spin: ISpin | null) {
		this._currentSpin = spin;
	}

	clear = () => {
		this._currentSpin = null;
	};
}
