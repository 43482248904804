import {injectable, inject} from "inversify";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import {ISpin} from "data/stores/wheel/spins.store";

export interface ISpinParams {
	wheelId: number;
}

export interface ISpinResponse {
	spin: ISpin;
}

export interface ISpinProvider {
	spin: (params: ISpinParams) => {
		get: () => Promise<AxiosResponse<IApiResponse<ISpinResponse>>>;
		post: () => Promise<AxiosResponse<IApiResponse<ISpinResponse>>>;
	};
	spins: () => {
		get: () => Promise<
			AxiosResponse<
				IApiResponse<{
					spins: ISpin[];
				}>
			>
		>;
	};
}

@injectable()
export class SpinProvider implements ISpinProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	spin = ({wheelId, ...params}: ISpinParams) => {
		return {
			get: () => this._http.get<IApiResponse<ISpinResponse>>(`spins/${wheelId}`, params),
			post: () => this._http.post<IApiResponse<ISpinResponse>>(`spins/${wheelId}`, params),
		};
	};

	spins = () => {
		return {
			get: () => this._http.get<IApiResponse<{spins: ISpin[]}>>(`spins`),
		};
	};
}
