import React from "react";
import {RouteProps, Outlet} from "react-router-dom";
import {observer} from "mobx-react";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {Session} from "views/components/session/session.component";

export const BootstrapRoute: React.FC<RouteProps> = observer(() => {
	return (
		<Bootstrap>
			<Session>
				<Outlet />
			</Session>
		</Bootstrap>
	);
});
