import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPostMessageStore} from "data/stores/post_message/post_message.store";

export interface IPostMessageService {
	sendMessageToParent(message: string): void;
	startListening(): void;
	stopListening(): void;
}

@injectable()
export class PostMessageService implements IPostMessageService {
	constructor(@inject(Bindings.PostMessageStore) private _postMessageStore: IPostMessageStore) {}

	public sendMessageToParent(message: string) {
		if (window.parent) {
			window.parent.postMessage(
				JSON.stringify({
					type: "client-event",
					data: message,
				}),
				"*"
			);

			this._postMessageStore.addClientMessage(message);
		} else {
			console.error("No parent window to post message to.");
		}
	}

	public startListening() {
		window.addEventListener("message", this.receiveMessageFromParent);
	}

	public stopListening() {
		window.removeEventListener("message", this.receiveMessageFromParent);
	}

	private receiveMessageFromParent = (event: MessageEvent) => {
		console.log(event.data);
		this._postMessageStore.addMessage(event.data as string);
		if (event.origin.includes("geniussports.com")) {
			this._postMessageStore.addMessage(event.data as string);
		}
	};
}
