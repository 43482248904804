import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {type IWheelsStore, WheeType} from "data/stores/wheels/wheels.store";

export interface ISessionController extends ViewController {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	@observable _isSessionChecked = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.WheelsStore) private _wheelStore: IWheelsStore
	) {
		makeAutoObservable(this);
	}

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	@action init() {
		void this._userStore
			.requestUser()
			.catch(() => {
				// Do nothing as the error is expected when a user isn't authorized
			})
			.then(() => {
				const user = this._userStore.user;
				if (user) {
					runInAction(() => {
						this._wheelStore.setUserWheelType(
							user.isLocal ? WheeType.Local : WheeType.Global
						);
					});
				}

				runInAction(() => {
					this._isSessionChecked = true;
				});
			});
	}
}
