import {makeAutoObservable, observable, runInAction, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISpinsStore} from "data/stores/wheel/spins.store";
import {type IEventsStore, IEvent, EventStatus} from "data/stores/events/events.store";
import {RequestState, ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IWheelsStore, ISlice} from "data/stores/wheels/wheels.store";
import {chain, take} from "lodash";
import type {IUserStore} from "data/stores/user/user.store";

interface IEventWithSpinSlice {
	spinSlices: ISlice[];
	event: IEvent;
}
export interface IRacesListController extends ViewController {
	i18n: ILocalizationStore;

	get scheduledEventsWithSpins(): IEventWithSpinSlice[];
	get completedEventsWithSpins(): IEventWithSpinSlice[];
	get isLoading(): boolean;
	get hasMore(): boolean;
	get noRaces(): boolean;
	showMore: () => void;
}

@injectable()
export class RacesListController implements IRacesListController {
	@observable _limit = 5;
	@observable _page = 1;

	@observable _disposer: ReturnType<typeof reaction> | null = null;
	@observable private _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SpinsStore) public readonly _spinsStore: ISpinsStore,
		@inject(Bindings.ModalsStore) public readonly _modalsStore: IModalsStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.WheelsStore) private _wheelsStore: IWheelsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get hasMore(): boolean {
		return this.completedEventsWithSpinsFull.length > this.limitCount;
	}

	get noRaces(): boolean {
		return !this.completedEventsWithSpins.length && !this.scheduledEventsWithSpins.length;
	}

	get scheduledEventsWithSpins(): IEventWithSpinSlice[] {
		return this.eventsWithSpins.filter((it) => it.event.status === EventStatus.SCHEDULED);
	}

	get completedEventsWithSpins(): IEventWithSpinSlice[] {
		return this._userStore.isAuthorized
			? take(this.completedEventsWithSpinsFull, this.limitCount)
			: [];
	}

	get completedEventsWithSpinsFull(): IEventWithSpinSlice[] {
		return this.eventsWithSpins.filter((it) => it.event.status === EventStatus.COMPLETE);
	}

	get eventsWithSpins(): IEventWithSpinSlice[] {
		const events = this._eventsStore.list;
		const wheels = this._wheelsStore.list;
		const spins = this._spinsStore.allSpins;
		const spinWheelsId = spins.map((it) => it.wheelId);

		return events.map((event) => {
			const wheelsOfEvent = chain(wheels)
				.filter((wheel) => wheel.eventId === event.id && spinWheelsId.includes(wheel.id))
				.flatMap((it) => it.slices)
				.uniq()
				.value();

			return {
				event: event,
				spinSlices: wheelsOfEvent,
			};
		});
	}

	init() {
		this._disposer = reaction(
			() => this._userStore.isAuthorized,
			(isLoggedIn) => {
				if (isLoggedIn) {
					void this._fetchSpinsList();
				}
			},
			{fireImmediately: true}
		);
	}

	dispose() {
		this._disposer?.();
	}

	private async _fetchSpinsList() {
		runInAction(() => {
			this._requestState = RequestState.PENDING;
		});
		try {
			await this._spinsStore.fetchSpins();
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});

			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}

	private get limitCount() {
		return this._page * this._limit;
	}
	showMore = () => {
		this._page = this._page + 1;
	};
}
