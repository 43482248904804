import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAuthApiProvider, ILoginPayload} from "data/providers/api/auth.api.provider";
import type {
	IUserApiProvider,
	IRegistrationPayload,
	IUpdateUserPayload,
} from "data/providers/api/user.api.provider";

export interface IUser {
	id: number;
	email: string;
	username: string;
	displayName: string;
	isNotificationsEnabled: boolean;
	createdAt: string;
	isOptIn: boolean;
	isLocal: boolean;
	terms: boolean;
}

export interface IUserStore {
	get user(): IUser | undefined;
	get isAuthorized(): boolean;
	get wasLoggedOut(): boolean;

	register(payload: IRegistrationPayload): Promise<void>;
	update(payload: IUpdateUserPayload): Promise<void>;
	deactivate(): Promise<void>;
	login(payload: ILoginPayload): Promise<void>;
	logout(): Promise<void>;

	clearUser(): void;
	requestUser(): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	@observable private _user?: IUser = undefined;
	@observable private _wasLoggedOut = false;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider
	) {
		makeAutoObservable(this);
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get user() {
		return this._user;
	}

	@action
	async requestUser() {
		try {
			const response = await this._userApi.user();
			const {user} = response.data.success;

			runInAction(() => {
				if (user.terms) {
					this._user = user;
				} else {
					this._wasLoggedOut = true;
					this._user = undefined;
				}
			});
		} catch (_e) {
			this._wasLoggedOut = true;
		}
	}

	@action
	async login(payload: ILoginPayload) {
		const response = await this._authApi.login(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async register(payload: IRegistrationPayload) {
		const response = await this._userApi.register(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async update(payload: IUpdateUserPayload) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout() {
		try {
			await this._authApi.logout();

			runInAction(() => {
				this._user = undefined;
				this._wasLoggedOut = true;
			});
		} catch (e) {
			// Expected errors
		}
	}

	clearUser() {
		this._wasLoggedOut = true;
		this._user = undefined;
	}

	@action
	async deactivate() {
		await this._userApi.deactivateAccount();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}
}
