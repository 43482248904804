/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {PaletteColorOptions, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface CustomPalette {
		apple: PaletteColorOptions;
	}

	interface Palette extends CustomPalette {}

	interface PaletteOptions extends CustomPalette {}
}

export const paletteTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#0078C9",
		},
		secondary: {
			main: "#99FE88",
		},
		apple: {
			main: "#FFF",
		},
		error: {
			main: "#ff0000",
		},
		warning: {
			main: "#fff100",
		},
		info: {
			main: "#00d4dc",
		},
		success: {
			main: "#60ff00",
		},
	},
};
