import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISpinsStore} from "data/stores/wheel/spins.store";
import {type IWheelsStore, WheelStatus, ISlice, SliceType} from "data/stores/wheels/wheels.store";
import type {IEventsStore, IEvent} from "data/stores/events/events.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IResultController extends ViewController {
	get isWin(): boolean;
	get currentWheelPrize(): ISlice | undefined;
	get currentEvent(): IEvent | undefined;

	i18n: ILocalizationStore;
}

@injectable()
export class ResultController implements IResultController {
	get spin() {
		return this._spinsStore.currentSpin;
	}

	get isWin(): boolean {
		return this.currentWheelPrize?.type === SliceType.WIN;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SpinsStore) public readonly _spinsStore: ISpinsStore,
		@inject(Bindings.WheelsStore) private _wheelStore: IWheelsStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get currentWheel() {
		const event = this.currentEvent;
		if (!event) {
			return;
		}

		return this._wheelStore.list.find(
			(it) => it.eventId === event.id && it.status === WheelStatus.ACTIVE
		);
	}

	get currentEvent() {
		return this._eventsStore.currentEvent;
	}

	get currentWheelPrize() {
		return this.currentWheel?.slices.find((it) => it.id === this.spin?.sliceId);
	}
}
